import React, { useEffect, useState } from 'react';
import { Card, message, Spin } from 'antd';
import axios from 'axios';

const IdentificarConsumidor = ({
  isVisible,
  onClose,
  nomeDoCupom,
  documentoDoCupom,
  setConsumidorIdentificado,
  consumidorIdentificado,
  consumidor,
  setConsumidor,
}) => {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [documentoRgIE, setDocumentoRgIE] = useState('');
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [paises, setPaises] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState(
    consumidor?.endereco?.cidade?.cuf,
  );
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [cpfCnpjMask, setCpfCnpjMask] = useState('cpf');
  const [codigoPais, setCodigoPais] = useState('55');


  const [restauranteId, setRestauranteId] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const operador = window.localStorage.getItem('operador');

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          'https://techspdv-api-d3e739785e34.herokuapp.com/v1/paises',
          options,
        )
        .then(paises => {
          const comboPaises = paises.data._embedded.paises.map(pais => ({
            label: `${pais.codigo} - ${pais.nome}`,
            value: `${pais.codigo}`,
          }));

          setPaises(comboPaises);
        });
    }
  }, [idUser, accessToken]);
  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setRestauranteId(restaurante.data.id);
        });

      const response2 = axios
        .get(
          'https://techspdv-api-d3e739785e34.herokuapp.com/v1/estados',
          options,
        )
        .then(estados => {
          const comboEstados = estados.data._embedded.estados.map(estado => ({
            label: `${estado.nome} - ${estado.uf}`,
            value: estado.cuf,
          }));

          setEstados(comboEstados);
        });
    }
  }, [idUser, accessToken]);

  useEffect(() => {
    if (accessToken && idUser && estadoSelecionado) {
      setCidades([]);
      setCidadeSelecionada(null);

      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      const response3 = axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/cidades/listarPorEstadoCuf/${estadoSelecionado}`,
          options,
        )
        .then(cidades => {
          const comboCidades = cidades.data._embedded.cidades.map(cidade => ({
            label: `${cidade.nome}`,
            value: cidade.ibge,
            id: cidade.id,
          }));

          setCidades(comboCidades);

          setIsLoading(false);
        });
    }
  }, [idUser, accessToken, estadoSelecionado]);

  useEffect(() => {
    if (consumidor == true) {
    } else {
      if (consumidor) {
        setCpfCnpj(consumidor.documentoCpfCnpj);
        setNome(consumidor.nomeCompleto);
        setEmail(consumidor.email);
        handleTelefoneChange(consumidor.telefone ? consumidor.telefone.slice(2) : '' );
        if (consumidor.endereco) {
          setLogradouro(consumidor.endereco.logradouro);
          setNumero(consumidor.endereco.numero);
          setComplemento(consumidor.endereco.complemento);
          setBairro(consumidor.endereco.bairro);
          setDocumentoRgIE(consumidor.documentoRgIE);
          setCep(consumidor.endereco.cep);
          setCidadeSelecionada(consumidor.endereco.cidade.ibge);
          setEstadoSelecionado(consumidor.endereco.cidade.cuf);
        }
      }
    }
  }, [consumidor]);

  const confirmarConsumidor = () => {
    setConsumidorIdentificado(true);
    let consumidorAux = {};
    console.log(nome);
    consumidorAux.nomeCompleto = nome;
    consumidorAux.documentoCpfCnpj = cpfCnpj;
    consumidorAux.documentoRgIE = documentoRgIE;
    consumidorAux.email = email;

    if (consumidorAux.endereco) {
      consumidorAux.endereco.cep = cep ? cep : '';
      consumidorAux.endereco.logradouro = logradouro;
      consumidorAux.endereco.numero = numero;
      consumidorAux.endereco.complemento = complemento;
      consumidorAux.endereco.bairro = bairro;
      consumidorAux.endereco.cidade.id = cidadeSelecionada;
      consumidorAux.endereco.cidade.cuf = estadoSelecionado;
    }

    documentoDoCupom(cpfCnpj);
    nomeDoCupom(consumidorAux.nomeCompleto);
    setConsumidor(consumidorAux);
    onClose();
    return;
  };
  const salvarConsumidor = () => {
    const cidadeSelecionadaAux = cidades.find(
      cidade => cidade.value === cidadeSelecionada,
    );

    setIsLoading(true);

    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    const consumidorForm = {
      ativo: true,
      documentoCpfCnpj: cpfCnpj,
      documentoRgIE: documentoRgIE,
      email: email,
      endereco: {
        bairro: bairro,
        cep: cep,
        cidade: {
          id: cidadeSelecionadaAux.id,
        },
        complemento: complemento,
        logradouro: logradouro,
        numero: numero,
      },
      nomeCompleto: nome,
      telefone: limparStringNumerica(`${codigoPais}${telefone}`),
      dataCadastro: consumidor.dataCadastro,
    };

    if (consumidor !== undefined && consumidor !== true) {
      axios
        .put(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/clientes/loja/${restauranteId}/${consumidor.id}`,
          consumidorForm,
          options,
        )
        .then(response => {
          setConsumidor(response.data);
          documentoDoCupom(response.data.documentoCpfCnpj);
          nomeDoCupom(response.data.nomeCompleto);
          setConsumidorIdentificado(true);
          setIsLoading(false);
          onClose();
          return;
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Erro:', error);
        });
    } else {
      axios
        .post(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/clientes/loja/${restauranteId}`,
          consumidorForm,
          options,
        )
        .then(response => {
          setConsumidor(response.data);
          documentoDoCupom(response.data.documentoCpfCnpj);
          nomeDoCupom(response.data.nomeCompleto);
          setConsumidorIdentificado(true);
          setIsLoading(false);
          onClose();
          return;
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Erro:', error);
        });
    }
  };
  const limparDados = () => {
    setNome('');
    setEmail('');
    setConsumidor(null);
    nomeDoCupom('');
    documentoDoCupom('');
    setCpfCnpj('');
    setTelefone('');
    setDocumentoRgIE('');
    setCep('');
    setBairro('');
    setLogradouro('');
    setNumero('');
    setConsumidorIdentificado(false);
  };
  function validarDocumento(documento) {
    // Remover caracteres não numéricos
    documento = documento.replace(/[^\d]+/g, '');

    // Verificar se o documento é um CPF
    if (documento.length === 11) {
      let cpf = documento;
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(9))) return false;

      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(10))) return false;

      return true;
    }

    // Verificar se o documento é um CNPJ
    else if (documento.length === 14) {
      let cnpj = documento;
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(0))) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(1))) return false;

      return true;
    }

    // Se não for nem CPF nem CNPJ, retorna false
    else {
      return false;
    }
  }
  const buscarConsumidor = () => {
    if (validarDocumento(cpfCnpj)) {
      const numericText = cpfCnpj.replace(/\D/g, '');
      setIsCnpj(numericText.length > 11);

      if (consumidor) {
        onClose();
        return;
      } else {
        if (accessToken) {
          setIsLoading(true);
          const options = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
            },
          };

          axios
            .get(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/clientes/loja/${restauranteId}/cpfcnpj/${cpfCnpj}`,
              options,
            )
            .then(consumidorResp => {
              if (consumidorResp) {
                if (consumidorResp.data) {
                  setConsumidor(consumidorResp.data);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                  setConsumidor(true);
                }
              } else {
                setIsLoading(false);
                setConsumidor(true);
              }
            }).catch(error => {
                console.error('Erro ao buscar consumidor:', error);
                setConsumidor(true); 
              })
        }
      }
    }
  };

  useEffect(() => {
    if (cep.length === 8) {
      buscarEnderecoPorCep();
    }
  }, [cep]);

  const buscarEnderecoPorCep = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const {data} = response;
      setBairro(data.bairro);
      setLogradouro(data.logradouro);
      if (data.ibge.length >= 2) {
        setEstadoSelecionado(data.ibge.substring(0, 2));
        setCidadeSelecionada(data.ibge);
      }
    } catch (error) {
      console.error('Erro ao buscar endereço:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeCpfCnpj = text => {
    setCpfCnpj(text);

    // Remove caracteres não numéricos
    const numericText = text.replace(/\D/g, '');

    // Detecta se é CPF (11 dígitos) ou CNPJ (14 dígitos)
    if (numericText.length === 11) {
      setCpfCnpjMask('cpf');
    } else if (numericText.length === 14) {
      setCpfCnpjMask('cnpj');
    }
  };

  const [isCnpj, setIsCnpj] = useState(false);

  const toggleMask = text => {
    const numericText = text.replace(/\D/g, '');

    setIsCnpj(numericText.length > 10);

    setCpfCnpj(numericText);
  };
  const validateEmail = email => {
    // Expressão regular para validar o formato do e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = text => {
    setEmail(text);
  };

  const handleEmailBlur = () => {
    if (email.trim() !== '' && !validateEmail(email)) {
      message('E-mail Inválido', 'Por favor, insira um e-mail válido.');
    }
  };

  const handleTelefoneChange = text => {
    // Remove todos os caracteres não numéricos
    const numeroTelefone = text.replace(/\D/g, '');

    // Formata o número de telefone conforme necessário
    let telefoneFormatado = '';
    if (numeroTelefone.length <= 10) {
      // Formatação para telefones fixos (DDD + 8 ou 9 dígitos)
      telefoneFormatado = numeroTelefone.replace(
        /^(\d{2})(\d{4})(\d{4})$/,
        '($1) $2-$3',
      );
    } else {
      // Formatação para telefones celulares (DDD + 9 dígitos)
      telefoneFormatado = numeroTelefone.replace(
        /^(\d{2})(\d{5})(\d{4})$/,
        '($1) $2-$3',
      );
    }

    // Atualiza o estado com o número de telefone formatado
    setTelefone(telefoneFormatado);
  };

  function limparStringNumerica(string) {
    return string.replace(/[^0-9\s]/g, '');
  }







  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Identificar Consumidor</h1>
      </header>
      <div>
        <label style={styles.label}>CPF ou CNPJ</label>
        <input
          type="text"
          placeholder="Cpf/Cnpj"
          value={cpfCnpj}
          onChange={(e) => toggleMask(e.target.value)}
          disabled={consumidor !== null}
          style={styles.input}
        />
        {consumidor && (
          <>
            <label style={styles.label}>Nome</label>
            <input
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              style={styles.input}
            />

            <label style={styles.label}>E-mail</label>
            <input
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              onBlur={handleEmailBlur}
              autoComplete="email"
              style={styles.input}
            />

            <label style={styles.label}>Telefone</label>
            <div style={{ display: "flex", gap: "10px", marginBottom: "12px" }}>
              <select
                value={codigoPais}
                onChange={(e) => setCodigoPais(e.target.value)}
                style={styles.select}
              >
                {paises.map((pais) => (
                  <option key={pais.value} value={pais.value}>
                    {pais.label}
                  </option>
                ))}
              </select>
              <input
                type="tel"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => handleTelefoneChange(e.target.value)}
                style={styles.input}
              />
            </div>

            <label style={styles.label}>RG ou IE</label>
            <input
              type="text"
              placeholder="Rg/Ie"
              value={documentoRgIE}
              onChange={(e) => setDocumentoRgIE(e.target.value)}
              style={styles.input}
            />

            <label style={styles.label}>Cep</label>
            <input
              type="text"
              placeholder="Cep"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
              maxLength={8}
              style={styles.input}
            />

            <label style={styles.label}>Estado</label>
            <select
              value={estadoSelecionado}
              onChange={(e) => setEstadoSelecionado(e.target.value)}
              style={styles.select}
            >
              {estados.map((estado) => (
                <option key={estado.value} value={estado.value}>
                  {estado.label}
                </option>
              ))}
            </select>

            <label style={styles.label}>Cidade</label>
            <select
              value={cidadeSelecionada}
              onChange={(e) => setCidadeSelecionada(e.target.value)}
              style={styles.select}
            >
              {cidades.map((cidade) => (
                <option key={cidade.value} value={cidade.value}>
                  {cidade.label}
                </option>
              ))}
            </select>

            <label style={styles.label}>Bairro</label>
            <input
              type="text"
              placeholder="Bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              style={styles.input}
            />

            <label style={styles.label}>Logradouro</label>
            <input
              type="text"
              placeholder="Logradouro"
              value={logradouro}
              onChange={(e) => setLogradouro(e.target.value)}
              style={styles.input}
            />

            <label style={styles.label}>Número</label>
            <input
              type="text"
              placeholder="Numero"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              style={styles.input}
            />

            <label style={styles.label}>Complemento</label>
            <input
              type="text"
              placeholder="Complemento"
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
              style={styles.input}
            />
          </>
        )}
        <div style={styles.actions}>
          {consumidor ? (
            <>
              <button onClick={confirmarConsumidor} style={styles.button}>
                Confirmar Consumidor na Venda
              </button>
              <button
                onClick={() => {
                  onClose();
                  if (!consumidorIdentificado) {
                    setConsumidor(null);
                    nomeDoCupom("");
                    documentoDoCupom("");
                  }
                }}
                style={{ ...styles.button, ...styles.buttonRed }}
              >
                Fechar
              </button>
              <button onClick={limparDados} style={styles.button}>
                Limpar Dados do Consumidor
              </button>
              <button onClick={salvarConsumidor} style={styles.button}>
                Atualizar Cadastro e Confirmar
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  onClose();
                  if (!consumidorIdentificado) {
                    setConsumidor(null);
                    nomeDoCupom("");
                    documentoDoCupom("");
                  }
                }}
                style={{ ...styles.button, ...styles.buttonRed }}
              >
                Fechar
              </button>
              <button
                onClick={buscarConsumidor}
                disabled={isLoading}
                style={{
                  ...styles.button,
                  ...(isLoading && styles.buttonDisabled),
                }}
              >
                Buscar
                {isLoading && <span> Carregando...</span>}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );


};
  const styles = {
    container: {
      padding: "16px",
      maxWidth: "600px",
      margin: "0 auto",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },
    headerButton: {
      padding: "8px 12px",
      backgroundColor: "#ff5c5c",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
    },
    label: {
      display: "block",
      marginBottom: "6px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#555",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginBottom: "12px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "14px",
      boxSizing: "border-box",
    },
    button: {
      width: "100%",
      padding: "10px",
      margin: "6px 0",
      backgroundColor: "#000000",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
    buttonRed: {
      backgroundColor: "#ff5c5c",
    },
    buttonDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
    actions: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    actionButton: {
      width: "48%",
      marginBottom: "8px",
      padding: "10px",
    },
  };


export default IdentificarConsumidor;
