import { Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PaymentList from "./PaymentList";
import { BlinkBlur, OrbitProgress } from "react-loading-indicators";

const PaymentScreen = ({ isVisible,
  onClose,
  precoTotal,
  setPagamentoConcluido,
  setPagItem,
  pagamentos,
  setPagamentos,
  idLoja, }) => {






  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const operador = window.localStorage.getItem("operador");
  const [restauranteId, setRestauranteId] = useState('');
  const [formasDePagamento, setFormasDePagamento] = useState([]);
  const [totalPago, setTotalPago] = useState(0);
  const [loading, setLoading] = useState(true);
  const [visualizarModalVoucher, setVisualizarModalVoucher] = useState(false);

  const [voucher, setVoucher] = useState('');
  const [informacoesVoucher, setInformacoesVoucher] = useState('');
  const [valor, setValor] = useState('');







  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setRestauranteId(restaurante.data.id);
        });
    }
  }, [idUser, accessToken]);

  useEffect(() => {
    if (accessToken && idUser && restauranteId) {
      setLoading(true);
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      console.log(accessToken);
      const response2 = axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/formas-pagamento`,
          options,
        )
        .then(formasPagamento => {
          console.log('Fora do Map');
          console.log(formasPagamento.data._embedded.formasPagamento);
          var novaFormaDePagamento = [];
          formasPagamento.data._embedded.formasPagamento.map(formaPag => {
            console.log('Dentro do Map');
            console.log(formaPag.codigo);
            console.log(formaPag.descricao);
            novaFormaDePagamento.push({
              label: formaPag.descricao,
              value: formaPag.codigo,
            });
          });
          setFormasDePagamento(novaFormaDePagamento);
          setLoading(false);
        });
    }
  }, [idUser, accessToken, restauranteId]);

  const [formaDePagamentoSelecionada, setFormaDePagamentoSelecionada] =
    useState('01');

  useEffect(() => {
    console.log('precoTotal');
    console.log('precoTotal');
    console.log('precoTotal');
    console.log('precoTotal');
    console.log(precoTotal);
    console.log((parseFloat(precoTotal) - parseFloat(totalPago)).toFixed(2));
    console.log(totalPago);
    console.log(valor);

    // Formatar o valor com a máscara (por exemplo, R$ 1.000,00)
    let valorFormatado = parseFloat(parseFloat(precoTotal) - parseFloat(totalPago)); // Converter para valor decimal
    valorFormatado = valorFormatado.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    setValor(valorFormatado);
    console.log(formaDePagamentoSelecionada);
  }, [precoTotal]);
  function formatISODate(isoString) {
    const date = new Date(isoString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Mês é zero-indexado
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const tratarValor = text => {
    console.log('valor');
    console.log(text);
    let valorFormatado = text.replace(/\D/g, '');
    // Verificar se o valor é vazio ou contém apenas zeros
    if (valorFormatado === '' || parseInt(valorFormatado) === 0) {
      setValor('R$ 0'); // Define o valor como vazio
      valorFormatado = 'R$ 0';
    } else {
      // Formatar o valor com a máscara (por exemplo, R$ 1.000,00)
      valorFormatado = parseFloat(valorFormatado) / 100; // Converter para valor decimal
      valorFormatado = valorFormatado.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    setValor(valorFormatado);
  };

  const [paymentType, setPaymentType] = useState("Cartão de Débito");
  const [amount, setAmount] = useState("");
  const [payments, setPayments] = useState([]);

  const handleAddPayment = () => {
    if (amount && parseFloat(amount) > 0) {
      setPayments((prevPayments) => [
        ...prevPayments,
        { type: paymentType, amount: parseFloat(amount) },
      ]);
      setAmount(""); // Limpa o campo de valor
    }
  };

  const adicionarPagamento = () => {
    if (formaDePagamentoSelecionada === 5) {
      console.log('Abrir modal');
      setVisualizarModalVoucher(true);
      return;
    }

    const totalPagoAux = parseFloat(totalPago);
    const valorDigitadoAux = parseFloat(
      valor.replace('R$', '').replace('.', '').replace(',', '.').trim()
    );


    if (valorDigitadoAux == 0) {
      return;
    }
    console.log('adicionarPagamento');
    console.log('Forma de pagamento selecionada:', formaDePagamentoSelecionada);
    console.log('Valor digitado:', valor);
    console.log('Preço total:', precoTotal);
    console.log('Total pago auxiliar:', totalPagoAux);
    console.log('Valor digitado auxiliar:', valorDigitadoAux);

    if (totalPagoAux + valorDigitadoAux <= parseFloat(precoTotal)) {
      console.log('formaDePagamentoSelecionada');

      console.log(formaDePagamentoSelecionada);
      if (valor && formaDePagamentoSelecionada) {
        const formaDePagamentoFiltrada = formasDePagamento.find(
          (formaPag) => formaPag.value === formaDePagamentoSelecionada
        );
        console.log(formasDePagamento);
        console.log('Forma de pagamento filtrada:', formaDePagamentoFiltrada);
        console.log('Forma de pagamento é válida:', formaDePagamentoFiltrada !== undefined);

        if (formaDePagamentoFiltrada !== undefined) {
          setTotalPago(
            totalPagoAux + valorDigitadoAux
          );

          const novosPagamentos = [
            ...pagamentos,
            {
              tipo: formaDePagamentoSelecionada,
              tipoNome: formaDePagamentoFiltrada.label,
              valor: valor,
            },
          ];

          setPagamentos(novosPagamentos);
          console.log('Preço restante:', precoTotal - totalPagoAux - valorDigitadoAux);

          setValor(
            (precoTotal - (totalPagoAux + valorDigitadoAux)).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          );
        }
      } else {
        console.log('Faltam o valor ou a forma de pagamento');
      }
    } else {
      console.log('Pagamento superior ao valor da compra');
    }
  };



  const confirmarPagamento = () => {
    setPagamentoConcluido(true);
    let pagItemAux = new Array();
    pagamentos.map(pagamento => {
      let valorFormatado = typeof pagamento.valor === 'string' ? parseFloat(pagamento.valor.replace(/\D/g, '')) / 100 : pagamento.valor;

      let pagamentoFormatado = {
        tPag: pagamento.tipo,
        vPag: valorFormatado.toFixed(2),
        xPag: pagamento.tipoNome,
      };
      if (pagamento.tipo == 5) {
        pagamentoFormatado.idReferencialVoucher = informacoesVoucher.id;
      }

      pagItemAux.push(pagamentoFormatado);
    });
    setPagItem(pagItemAux);

    onClose();
  };













  /*const handleRemovePayment = (index) => {
    setPayments((prevPayments) =>
      prevPayments.filter((_, i) => i !== index)
    );
  };*/

  const total = 1.5;
  const paid = payments.reduce((sum, payment) => sum + payment.amount, 0);

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>


      <div style={{ marginBottom: "10px" }}>
        {loading ? (
          <div className="loading">
            <OrbitProgress variant="spokes" color="#646464" size="large" text="carregando" textColor="" />

            <div className="spinner"></div>
          </div>
        ) : (
          <div>
                  <h1 style={{ textAlign: 'center', marginBottom: '5px', marginTop: 0 }}>Formas de Pagamento</h1>
            <Select
              style={{
                width: '100%', // Usa toda a largura disponível do contêiner
                maxWidth: '300px', // Define um limite máximo de largura
                minWidth: '150px', // Define um limite mínimo de largura
              }}
              placeholder="Selecione a forma de pagamento"
              value={formaDePagamentoSelecionada}
              // onChange={(value) => setFormaDePagamentoSelecionada(value)}
              onChange={(value) => setFormaDePagamentoSelecionada(value)}
              dropdownMatchSelectWidth={false} // O dropdown pode ter largura própria
            >
              {formasDePagamento.map((forma) => (
                <Option key={forma.value} value={forma.value}>
                  {forma.label}
                </Option>
              ))}
            </Select>
              
            <input
              type="text"
              //placeholder="R$ 0,00"
              value={valor}
              onChange={(e) => tratarValor(e.target.value)}
              style={{ padding: "10px", marginRight: "10px" }}
            />
            <button onClick={adicionarPagamento} style={{ padding: "10px 20px", backgroundColor: 'black', color: 'white' }}>
              Adicionar Pagamento
            </button>
           
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
              <div>
                <strong>Total:</strong>
                {`${precoTotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </div>
              <div>
                <strong>Pago:</strong> {`${totalPago.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </div>
            </div>

            <PaymentList pagamentos={pagamentos} setPagamentos={setPagamentos} precoTotal={precoTotal} totalPago={totalPago} setTotalPago={setTotalPago} setValor={setValor} setPagamentoConcluido={setPagamentoConcluido}></PaymentList>

            <button
              disabled={parseFloat(precoTotal) !== totalPago ? true : false}
              style={{
                padding: "10px 20px",
                backgroundColor: parseFloat(precoTotal) > totalPago ? "#ccc" : "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: parseFloat(precoTotal) > totalPago ? "not-allowed" : "pointer",
              }}
              onClick={confirmarPagamento}
            >
              Confirmar
            </button>
          </div>



        )}
      </div>


    </div>
  );
};

export default PaymentScreen;
