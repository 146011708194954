import { Button, DatePicker, Input, List, Modal, Spin } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const BuscarOrcamentos = ({ onItemPress, closeModal, tratarProdutosOrcamento }) => {







  const dispatch = useDispatch();
  const [baixarImprimirPdf, setBaixarImprimirPdf] = useState(true);
  const [isModalVisibleLeitor, setIsModalVisibleLeitor] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [termo, setTermo] = useState('hoje');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [buscaPorData, setBuscaPorData] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [loja, setLoja] = useState('');
  const [dates, setDates] = useState(null);
  const { RangePicker } = DatePicker;

  const accessToken = window.localStorage.getItem('accessToken');
  const idUser = window.localStorage.getItem('idUser');
  const tpAmb = window.localStorage.getItem('tpAmb');

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then((restaurante) => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);



  const handleSaveDates = async (datesp) => {
    setBuscaPorData(true);
    setIsLoading(true);
    const dataInicio = datesp[0].toISOString().split('T')[0];
    const dataFim = datesp[1].toISOString().split('T')[0];


    if (accessToken) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${loja.id}/${dataInicio}/${dataFim}/4/${tpAmb}?page=0`,
          options
        );

        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleDateSelector = () => {
    setShowDateSelector(!showDateSelector);
  };

  const toggleModalLeitor = () => {
    setIsModalVisibleLeitor(!isModalVisibleLeitor);
  };


 /* const loadCupons = async () => {
    if (accessToken && termo) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        if (buscaPorData) {
          var response = '';
          console.log('loadCupons data()');
          const dataInicio = selectedStartDate.toISOString().split('T')[0];
          const dataFim = selectedEndDate.toISOString().split('T')[0];
          console.log(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${dataInicio}/${dataFim}/4/${tpAmb}?page=${currentPage + 1}`,
          );
          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${dataInicio}/${dataFim}/4/${tpAmb}?page=${currentPage + 1}`,
            options,
          );
          console.log(response.data);
        } else {
          console.log('loadCupons ()');
          console.log(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${termo}/4/${tpAmb}?page=${currentPage + 1}`,
          );
          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${
              loja.id
            }/${termo}/4/${tpAmb}?page=${currentPage + 1}`,
            options,
          );
        }

        if (response.data._embedded?.cupomFiscal.length > 0) {
          setTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);

          if (response.data.page.number !== 0) {
            console.log('adicionando nova pagina');
            setProducts([...products, ...response.data._embedded.PDVEvento]);
          } else {
            console.log('Adicionando array limpo');
            setProducts(response.data._embedded.PDVEvento);
          }
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };*/



  const buscaNotas = async termo => {
    setBuscaPorData(false);
    setTermo(termo);
    setCurrentPage(0);

    if (accessToken) {
      console.log('dentro da busca');

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };
        console.log('Buscar Notas ()');
        console.log(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${loja.id}/${termo}/4/${tpAmb}?page=0`,
        );
        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipo/${loja.id}/${termo}/4/${tpAmb}?page=0`,
          options,
        );

        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  function formatarCpfCnpj(cpfCnpj) {
    cpfCnpj = cpfCnpj.replace(/\D/g, '');

    if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return cpfCnpj;
    }
  }

  function formatarValorEmReais(valor) {
    const simboloMoeda = 'R$';
    const valorFormatado = parseFloat(valor).toFixed(2).replace('.', ',');

    const partes = valorFormatado.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return simboloMoeda + ' ' + partes.join(',');
  }

  
   const renderItem = ({ item }) => {
     const formattedDate = new Date(item.dataHoraFechamento).toLocaleString('pt-BR', {
       year: 'numeric',
       month: '2-digit',
       day: '2-digit',
       hour: '2-digit',
       minute: '2-digit',
       second: '2-digit',
     });
 
     return (
       <Button style={styles.card}
       onClick={() => handleItemPress(item)}
       >
         <span style={styles.cardText}>{item.tipoEvento}</span>
         {item.tipoEvento === 'VENDA' && (
             <span style={styles.cardText}>NF: {item.numeroDocFiscal}</span>
         )}
 
         {item.tipoEvento === 'VENDA' && (       
              <span style={styles.cardText}>Série: {item.serieDocFiscal}</span>            
         )}
 
         <span style={styles.cardText}>Data: {formattedDate}</span>
         <span style={styles.cardText}>
           Cliente: {item.cpf ? formatarCpfCnpj(item.cpf) : ''}
         </span>
         <span style={styles.cardText}>
           Valor: {formatarValorEmReais(item.valorTotalLiquido)}
         </span>
       </Button>
     );
   };


  const handleItemPress = async item => {
    closeModal();
    tratarProdutosOrcamento(item);
  };
  const buscarPorCodChavCpf = async () => {
    if (accessToken && searchTerm !== null) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };
        console.log('busca especifica');
       const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/chavenumerotipo/${loja.id}/${searchTerm}/0/${tpAmb}`,
          options,
        );
        console.log(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/chavenumerotipo/${loja.id}/${searchTerm}/0/${tpAmb}`,
        );
        if (response.data._embedded?.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDateChange = (dates) => {
    setDates(dates);
    if (dates && dates[0] && dates[1]) {
     setBuscaPorData(true);
     handleSaveDates(dates);
    }
  };


  return (
    <>
      <div style={styles.container}>
        <Modal
          visible={isModalVisibleLeitor}
          onCancel={toggleModalLeitor}
          footer={null}
        >
          <div style={styles.modalContainer}>
            
          </div>
        </Modal>


        <div style={styles.buttonContainer}>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              //toggleDateSelectorFalse();
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('hoje');
            }}
          >
            <span style={styles.buttonText}>Hoje</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              console.log('Após clicar em ontem');
              buscaNotas('ontem');
            }}
          >
            <span style={styles.buttonText}>Ontem</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('semana');
            }}
          >
            <span style={styles.buttonText}>Semana</span>
          </Button>
          {/*<Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={true}
            onClick={toggleDateSelector}
            disabled={true}
          >
            <span style={styles.buttonText}>Escolha</span>
          </Button>
         
     */}
   <RangePicker onChange={handleDateChange} />
   </div>

        <div style={styles.searchContainer}>
          <Input
            style={styles.input}
            placeholder="Pesquisar por EAN, Nome ou Código Interno"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <Button
            style={{ ...styles.searchButton, backgroundColor: 'black' }}
            loading={isLoading || !loja}
            disabled={!loja}
            onClick={async ()  => {
              setIsLoading(true);
             await buscarPorCodChavCpf();
            }}
          >
            <span style={styles.buttonText}>Pesquisar</span>
          </Button>
        </div>

        {isLoading ? (
          <center><Spin size="large" /></center>
        ) : (
          <List
          style={{ maxHeight: '650px', overflowY: 'auto' }} // Define uma altura máxima e permite rolagem vertical
          dataSource={products}
          renderItem={product => renderItem({ item: product })}
        />
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: '16px',
  
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  selectButton: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',      
  },
  selectButton2: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',     
  },
  buttonText: {
    color: 'white',
    fontSize: '16px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderRadius: '5px',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '21px',
    marginLeft: '10px',
    borderRadius: '5px',
    
  },
  card: {
    padding: '30px',
    margin: '8px 16px',
    borderRadius: '5px',
   backgroundColor: '#f9f9f9',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around'
  },
  cardText: {
    fontSize: '14px',
    color: '#333',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};
export default BuscarOrcamentos;
