import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

const LeitorQRCode = ({ onQRCodeScanned, closeModal }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [error, setError] = useState(null);

  const handleScan = (data) => {
    if (data) {
      console.log("QR Code lido:", data);
      setQrCodeData(data);
      setIsCameraOpen(false);
      onQRCodeScanned(data); // Chama uma ação com o dado do QR Code
      closeModal(); // Fecha o modal (se aplicável)
    }
  };

  const handleError = (err) => {
    console.error("Erro ao ler QR Code:", err);
    setError("Erro ao acessar a câmera ou ler o QR Code.");
  };

  return (
    <div style={styles.container}>
      {isCameraOpen ? (
        <div style={styles.cameraContainer}>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result, error) => {
              if (result) {
                handleScan(result?.text);
              }
              if (error) {
                handleError(error);
              }
            }}
            style={{ width: "100%" }}
          />
          <button style={styles.button} onClick={() => setIsCameraOpen(false)}>
            Fechar Câmera
          </button>
        </div>
      ) : (
        <div style={styles.content}>
          <button style={styles.button} onClick={() => setIsCameraOpen(true)}>
            Abrir Câmera
          </button>
          {qrCodeData && (
            <div style={styles.resultContainer}>
              <p>QR Code lido:</p>
              <strong>{qrCodeData}</strong>
            </div>
          )}
          {error && <p style={styles.error}>{error}</p>}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "20px",
    backgroundColor: "#f7f7f7",
  },
  cameraContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "500px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
  },
  content: {
    textAlign: "center",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  resultContainer: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#e9ecef",
    borderRadius: "5px",
    textAlign: "center",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default LeitorQRCode;
