import { Button, Input, List, Modal, Spin , DatePicker, Spac} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import "../styles.css";

const Reimpressao = () => {
  const [isModalVisibleLeitor, setIsModalVisibleLeitor] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);




  const dispatch = useDispatch();
  const [termo, setTermo] = useState('hoje');
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");   
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setotTotalPages] = useState(0);
  const [buscaPorData, setBuscaPorData] = useState(false);
  const [baixarImprimirPdf, setBaixarImprimirPdf] = useState(true);
  const [loja, setLoja] = useState('');
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [dates, setDates] = useState(null);


const { RangePicker } = DatePicker;



  useEffect(() => {
    
    if (accessToken && idUser) {

      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);





  const toggleDateSelector = () => {
    setShowDateSelector(!showDateSelector);
  };

  const toggleModalLeitor = () => {
    setIsModalVisibleLeitor(!isModalVisibleLeitor);
  };




  const buscaNotas = async termo => {
    console.log('buscaNotas');
    setBuscaPorData(false);
    setTermo(termo);
    setCurrentPage(0);
    console.log('accessToken');
    console.log(accessToken);

    if (accessToken && loja) {
      console.log('dentro da busca');

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipos/${loja.id}/${termo}/${tpAmb}/0,3?page=0`,
          options,
        );
        console.log(response.data);
        console.log(response.data._embedded);
        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
          setIsLoading(false);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const loadCupons = async () => {
    if (accessToken && termo) {
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        if (buscaPorData) {
          var response = '';
          console.log('loadCupons data()');

          console.log('Data inicial:', dates[0].format('YYYY-MM-DD'));
          console.log('Data final:', dates[1].format('YYYY-MM-DD'));
          const dataInicio = dates[0].toISOString().split('T')[0];
          const dataFim = dates[1].toISOString().split('T')[0];

          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/dataTipos/${
              loja.id
            }/${dataInicio}/${dataFim}/${tpAmb}/0,3?page=${currentPage + 1}`,
            options,
          );
        } else {

          response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipos/${
              loja.id
            }/${termo}/${tpAmb}/0,3?page=${currentPage + 1}`,
            options,
          );
        }

        if (response.data._embedded?.cupomFiscal.length > 0) {
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);

          if (response.data.page.number !== 0) {
            console.log('adicionando nova pagina');
            setProducts([...products, ...response.data._embedded.PDVEvento]);
          } else {
            console.log('Adicionando array limpo');
            setProducts(response.data._embedded.PDVEvento);
          }
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar historico:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };




  function formatarCpfCnpj(cpfCnpj) {
    cpfCnpj = cpfCnpj.replace(/\D/g, '');

    if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return cpfCnpj;
    }
  }

  function formatarValorEmReais(valor) {
    const simboloMoeda = 'R$';
    const valorFormatado = parseFloat(valor).toFixed(2).replace('.', ',');

    const partes = valorFormatado.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return simboloMoeda + ' ' + partes.join(',');
  }




  const downloadPDF = (b64, chaveDocFiscal) => {
    // Decode Base64 to binary data
    const binaryData = atob(b64);
  
    // Create a Uint8Array from the binary data
    const byteNumbers = new Uint8Array(
      new Array(binaryData.length).fill().map((_, i) => binaryData.charCodeAt(i))
    );
  
    // Create a Blob from the byte array
    const blob = new Blob([byteNumbers], { type: 'application/pdf' });
  
    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `PDF_${chaveDocFiscal}.pdf`;
  
    // Append the link to the document and click it to trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  
    // Optionally, you can notify the user that the file is being downloaded
    alert('O arquivo PDF está sendo baixado.');
  };


const printPDF = (base64Data) => {
  // Decodifica a string Base64 para um ArrayBuffer
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Criar um Blob com o conteúdo do PDF
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Criar uma URL para o Blob
  const url = window.URL.createObjectURL(blob);

  // Abrir o PDF em uma nova aba
  const newWindow = window.open(url);

  // Quando a nova aba carregar, chamar a função de impressão
  if (newWindow) {
    newWindow.onload = function () {
      newWindow.focus(); // Focar na nova aba
      newWindow.print(); // Chamar a função de impressão
    };
  } else {
    // Se a nova aba não puder ser aberta
    alert('Permita pop-ups para abrir o PDF');
  }
};





  const handleItemPress = async item => {
    console.log('handleItemPress');
    const docFiscal = JSON.parse(item.jsonDocFiscal);
       
    
        try {
    
          if(item.tipoEvento === 'VENDA'){
          const options = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
            },
          };
          const response = await axios.get(
            `https://technfe-api.herokuapp.com/v1/historico/chave/${item.chaveDocFiscal}`,
            options,
          );
    
    
          console.log(item.chaveDocFiscal);
         
       /*   downloadPDF(
            response.data._embedded.historico[0].pdfBase64,
            item.chaveDocFiscal,
          );*/

          printPDF(response.data._embedded.historico[0].pdfBase64);
        }else{
    function centralizeText(text, columnWidth) {
      const totalSpaces = columnWidth - text.length;
      const leftSpaces = Math.max(0, Math.floor(totalSpaces / 2));
      const rightSpaces = Math.ceil(totalSpaces / 2);
    
      const centeredText = text;
      return ' '.repeat(leftSpaces) + text;
    }
    const cupomWidth = 33;
          const enderecoAux = centralizeText(
            `${docFiscal.emit.enderEmit.xLgr}, ${docFiscal.emit.enderEmit.nro}, ${docFiscal.emit.enderEmit.xBairro}, ${docFiscal.emit.enderEmit.xMun} - ${docFiscal.emit.enderEmit.UF}`,
            cupomWidth,
          );
          
    
          const agora = new Date(docFiscal.InfoSistema.docDhAut);
          const ano = agora.getFullYear();
          const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
          const dia = agora.getDate().toString().padStart(2, '0');
          const hora = agora.getHours().toString().padStart(2, '0');
          const minuto = agora.getMinutes().toString().padStart(2, '0');
          const segundo = agora.getSeconds().toString().padStart(2, '0');
      
          const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
    
          const numeroDoOrcamentoOuPedido ='';
         // const telefoneLoja = '(11) 999999999999<br><br></br>';
     
          const telefoneLoja = '';
    
    
    
    
          let produtosString = ``;
          var stringPagamento = 'FORMAS DE PAGAMENTO<br>';
          docFiscal.pag.pagItem.map(pagamento => {
            stringPagamento += `${pagamento.xPag}  R$${pagamento.vPag}<br>`;
          });
    
          var quantidadeProdutos = 0;
          var totalProduto = 0;
          docFiscal.det.detItem.map(produto => {
            totalProduto =
              parseFloat(produto.prod.qTrib) *
              (parseFloat(produto.prod.vUnCom) -
                parseFloat(produto.prod.vDesc) +
                parseFloat(produto.prod.vOutro_item) +
                parseFloat(
                  produto.prod.vFrete !== undefined ? produto.prod.vFrete : '0',
                ));
            console.log(produto.prod.vFrete);
    
            quantidadeProdutos += parseFloat(produto.prod.qTrib);
            produtosString += `${
              produto.prod.cEANTrib !== 'SEM GTIN' && produto.prod.cEANTrib !== ''
                ? produto.prod.cEANTrib
                : produto.prod.cProd
            } ${produto.prod.xProd}<br>${parseFloat(produto.prod.qTrib).toFixed(
              4,
            )} UN   ${produto.prod.vUnCom}  -  ${parseFloat(
              produto.prod.vDesc,
            ).toFixed(2)}  +  ${parseFloat(produto.prod.vOutro_item).toFixed(
              2,
            )}  +  ${
              produto.prod.vFrete != undefined
                ? parseFloat(produto.prod.vFrete).toFixed(2)
                : '0'
            } = ${totalProduto.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            <br><br>`;
          });
    
    
          const htmlContent = `
          <html>
            <head>
              <meta charset="utf-8">
              <title>Cupom</title>
              <style>
                body {
                  font-family: monospace;
                  font-size: 10pt;
                }
              </style>
            </head>
            <body>
              ${docFiscal.emit.xNome}<br>
              CNPJ - ${docFiscal.emit.CNPJ_emit}<br>
              IE - ${docFiscal.emit.IE}<br>
              ${enderecoAux}<br>
              ${telefoneLoja}
              ${dataHoraFormatada}<br>
              PEDIDO ${numeroDoOrcamentoOuPedido}<br><br>
              CUPOM SEM VALOR FISCAL<br><br>
              ${produtosString}<br>
              Qtd. total de itens ${quantidadeProdutos}<br>
              Valor total R$ ${docFiscal.total.ICMStot.vNF}<br>${stringPagamento}
              <br><br>.
            </body>
          </html>
        `;
    
    
    console.log(htmlContent);
    
    /*
          try {
            const options = {
              html: htmlContent,
              fileName: 'cupom',
              directory: 'Download',
              base64: true,
              width: 220,
              height: 1000,
            };
            const file = await RNHTMLtoPDF.convert(options);
            console.log(file.filePath);
    
            Share.open({
              url: `file://${file.filePath}`,
            });
    
    
       
          } catch (error) {
            window.alert(
              'Erro ao Criar PDF',
              'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
            );
          }*/
        }
        } catch (error) {
          console.error('Erro ao carregar historico:', error);
        } finally {
          setIsLoading(false);
        }
      };

      const handleDateChange = (dates) => {
        setDates(dates);
        if (dates && dates[0] && dates[1]) {
         setBuscaPorData(true);
         handleSaveDates(dates);
        }
      };

      const buscarPorCodChavCpf = async () => {
        if (accessToken && searchTerm !== null) {
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };

          const response = await axios.get(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/chavenumerotipo/${loja.id}/${searchTerm}/0/${tpAmb}`,
              options,
            );

            if (response.data._embedded?.PDVEvento.length > 0) {
              setProducts(response.data._embedded.PDVEvento);
            } else {
              setCurrentPage(0);
              setProducts([]);
            }
          } catch (error) {
            console.error('Erro ao carregar historico:', error);
          } finally {
            setIsLoading(false);
          }
        }
      };

      const handleSaveDates = async (datesp) => {
        setBuscaPorData(true);
        setIsLoading(true);
        const dataInicio = datesp[0].toISOString().split('T')[0];
        const dataFim = datesp[1].toISOString().split('T')[0];
    
        if (accessToken) {
          console.log('dentro da busca');
    
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };
            const response = await axios.get(
              `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/dataTipos/${loja.id}/${dataInicio}/${dataFim}/${tpAmb}/0,3?page=0`,
              options,
            );
    
            if (response.data._embedded.PDVEvento.length > 0) {
              setProducts(response.data._embedded.PDVEvento);
              setotTotalPages(response.data.page.totalPages);
              setCurrentPage(response.data.page.number);
            } else {
              setCurrentPage(0);
              setProducts([]);
            }
          } catch (error) {
            setProducts([]);
            console.error('Erro ao carregar cupomFiscal:', error);
          } finally {
            setIsLoading(false);
          }
        }
      };

  const renderItem = ({ item }) => {
    const formattedDate = new Date(item.dataHoraFechamento).toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return (
      <Button style={styles.card}
      onClick={() => handleItemPress(item)}
      >
        <span style={styles.cardText}>{item.tipoEvento}</span>
        {item.tipoEvento === 'VENDA' && (
            <span style={styles.cardText}>NF: {item.numeroDocFiscal}</span>
        )}

        {item.tipoEvento === 'VENDA' && (       
             <span style={styles.cardText}>Série: {item.serieDocFiscal}</span>            
        )}

        <span style={styles.cardText}>Data: {formattedDate}</span>
        <span style={styles.cardText}>
          Cliente: {item.cpf ? formatarCpfCnpj(item.cpf) : ''}
        </span>
        <span style={styles.cardText}>
          Valor: {formatarValorEmReais(item.valorTotalLiquido)}
        </span>
      </Button>
    );
  };

  return (
    <>
      <div style={styles.container}>
              <h1 style={{ textAlign: 'center',marginBottom:'5px', marginTop:0 }}>Reimpressão</h1>
        <Modal
          visible={isModalVisibleLeitor}
          onCancel={toggleModalLeitor}
          footer={null}
        >
          <div style={styles.modalContainer}>
            
          </div>
        </Modal>


        <div style={styles.buttonContainer}>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              //toggleDateSelectorFalse();
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('hoje');
            }}
          >
            <span style={styles.buttonText}>Hoje</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              console.log('Após clicar em ontem');
              buscaNotas('ontem');
            }}
          >
            <span style={styles.buttonText}>Ontem</span>
          </Button>
          <Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={isLoading}
            disabled={!loja}
            onClick={() => {
              setIsLoading(true);
              setCurrentPage(0);
              buscaNotas('semana');
            }}
          >
            <span style={styles.buttonText}>Semana</span>
          </Button>
          {/*<Button
            style={{ ...styles.selectButton, backgroundColor: 'black' }}
            loading={true}
            onClick={toggleDateSelector}
            disabled={true}
          >
            <span style={styles.buttonText}>Escolha</span>
          </Button>
         
     */}
   <RangePicker onChange={handleDateChange} />
   </div>

        <div style={styles.searchContainer}>
          <Input
            style={styles.input}
            placeholder="Pesquisar por EAN, Nome ou Código Interno"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <Button
            style={{ ...styles.searchButton, backgroundColor: 'black' }}
            loading={isLoading || !loja}
            disabled={!loja}
            onClick={async ()  => {
              setIsLoading(true);
             await buscarPorCodChavCpf();
            }}
          >
            <span style={styles.buttonText}>Pesquisar</span>
          </Button>
        </div>

        {isLoading ? (
          <center><Spin size="large" /></center>
        ) : (
          <List
          style={{ maxHeight: '650px', overflowY: 'auto' }} // Define uma altura máxima e permite rolagem vertical
          dataSource={products}
          renderItem={product => renderItem({ item: product })}
        />
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: '16px',
  
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  selectButton: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',      
  },
  selectButton2: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',     
  },
  buttonText: {
    color: 'white',
    fontSize: '16px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderRadius: '5px',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '21px',
    marginLeft: '10px',
    borderRadius: '5px',
    
  },
  card: {
    padding: '30px',
    margin: '8px 16px',
    borderRadius: '5px',
   backgroundColor: '#f9f9f9',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around'
  },
  cardText: {
    fontSize: '14px',
    color: '#333',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

export default Reimpressao;
