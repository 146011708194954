import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select } from 'antd';

const { Option } = Select;

const Pagamento = ({
  isVisible,
  onClose,
  precoTotal,
  setPagamentoConcluido,
  setPagItem,
  pagamentos,
  setPagamentos,
  idLoja,
}) => {

  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  
  const [restauranteId, setRestauranteId] = useState('');
  const [formasDePagamento, setFormasDePagamento] = useState([]);
  const [totalPago, setTotalPago] = useState(0);
  const [valor, setValor] = useState('');


  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => setRestauranteId(response.data.id));
    }
  }, [accessToken, idUser]);

  useEffect(() => {
    if (restauranteId) {
      axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/formas-pagamento`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => {
          const options = response.data._embedded.formasPagamento.map((forma) => ({
            label: forma.descricao,
            value: forma.codigo,
          }));
          setFormasDePagamento(options);
        });
    }
  }, [restauranteId]);

  const handleValorChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    const formattedValue = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    setValor(formattedValue);
  };

  const adicionarPagamento = () => {
    const valorNumerico = parseFloat(valor.replace(/[^0-9,-]+/g, '').replace(',', '.'));
    if (valorNumerico + totalPago <= precoTotal) {
      setPagamentos((prev) => [
        ...prev,
        { tipo: '01', tipoNome: 'Dinheiro', valor: valorNumerico },
      ]);
      setTotalPago((prev) => prev + valorNumerico);
    }
  };

  const confirmarPagamento = () => {
    setPagamentoConcluido(true);
    setPagItem(pagamentos.map((pag) => ({
      tPag: pag.tipo,
      vPag: pag.valor,
      xPag: pag.tipoNome,
    })));
    onClose();
  };

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <h2>Pagamento</h2>
      <Select
        style={{ width: '100%' }}
        placeholder="Selecione a forma de pagamento"
        onChange={(value) => console.log(value)}
      >
        {formasDePagamento.map((forma) => (
          <Option key={forma.value} value={forma.value}>
            {forma.label}
          </Option>
        ))}
      </Select>
      <input
        type="text"
        value={valor}
        onChange={handleValorChange}
        placeholder="Digite o valor"
      />
      <button onClick={adicionarPagamento}>Adicionar Pagamento</button>
      <div>
        <h3>Total: {precoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
        <h3>Pago: {totalPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
      </div>
      <button onClick={confirmarPagamento}>Confirmar</button>
      <button onClick={onClose}>Fechar</button>
    </div>
  );
};

export default Pagamento;
