import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import './Pesquisar.css'; // Assumindo que os estilos foram movidos para um arquivo CSS
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pesquisar = ({ onItemPress, closeModal, setPagamentoConcluido, setPagamentos }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then(response => {
          setRestauranteId(response.data.id);
        });
    }
  }, [idUser, accessToken]);

  const handleItemPress = item => {
    item.quantidade = 1;
    item.acrescimo = 0;
    item.desconto = 0;
    item.valorAcrescimo = 0;
    item.valorDesconto = 0;
    item.vFrete = 0;
    onItemPress(item);
    closeModal();
    setPagamentoConcluido(false);
    setPagamentos([]);
  };

  const loadProducts = async () => {
    if (accessToken && restauranteId && searchTerm) {
      setIsLoading(true);

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/produtos/eancodnome/${searchTerm}?page=${currentPage}`,
          options
        );

        if (response.data._embedded?.produtos.length > 0) {
          setTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
          if (response.data.page.number !== 0) {
            setProducts(prev => [...prev, ...response.data._embedded.produtos]);
          } else {
            setProducts(response.data._embedded.produtos);
          }

          if (response.data.page.number + 1 < response.data.page.totalPages) {
            setCurrentPage(currentPage + 1);
          }
        } else {
          setCurrentPage(0);
          setProducts([]);
        }
      } catch (error) {
        console.error('Erro ao carregar produtos:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderItem = item => {
    const formattedPrice = item.preco.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <div className="card" onClick={() => handleItemPress(item)}>
        <span className="card-text">{item.nome}</span><br/>
        <span className="card-text-ean">{item.ean}</span><br/>
        <span className="card-text">{formattedPrice}</span>
      </div>
    );
  };

  return (
        <div className="container-pesquisar">
          <div className="modal-header">
            <Button  className="close-button"  onClick={closeModal} icon={<FontAwesomeIcon icon={faArrowLeft} size='lg' />} />
            <h2>Pesquisar</h2>
          </div>

          <div className="search-container">
            <input
              className="input"
              type="text"
              placeholder="Pesquisar por EAN, Nome ou Código Interno"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />

            <button
              className="search-button"
              disabled={!accessToken || !restauranteId || !searchTerm}
              onClick={loadProducts}
            >
              Pesquisar
            </button>
          </div>

          {isLoading ? (
            <div className="loading">Carregando...</div>
          ) : (
            <div className="scroll-container">
              <div className="product-list">
                {products.length > 0 ? (
                  products.map(product => <React.Fragment key={product.id}>{renderItem(product)}</React.Fragment>)
                ) : (
                  <p className="empty-list-text">Nenhum produto encontrado</p>
                )}
              </div>
            </div>
          )}
        </div>

  );
};

export default Pesquisar;
