import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PaymentList = ({ pagamentos, setPagamentos, precoTotal, totalPago, setTotalPago, setValor,setPagamentoConcluido}) => {
  const renderItem = (item) => {
    const formattedPrice = item.valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const handleRemovePayment = (itemToRemove) => {
        const confirmRemoval = window.confirm(
          'Tem certeza de que deseja remover o pagamento?'
        );
      
        if (confirmRemoval) {
          const updatedPayments = pagamentos.filter((item) => item !== itemToRemove);
          setPagamentos(updatedPayments);
      
          const valorDoItem = parseFloat(
            typeof itemToRemove.valor === 'string'
              ? itemToRemove.valor.replace('R$', '').replace('.', '').replace(',', '.').trim()
              : itemToRemove.valor
          );
      
          console.log('Valor do item removido:', valorDoItem);
      
          if (totalPago - valorDoItem >= 0) {
            setValor(
              (precoTotal - (totalPago - valorDoItem)).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            );
            setTotalPago(totalPago - valorDoItem);
            setPagamentoConcluido(false);
          } else {
            setTotalPago(0);
          }
        } else {
          console.log('Remoção cancelada');
        }
      };
      

    return (
      <div style={styles.card}>
        <div style={styles.cardContent}>
          <p style={styles.cardText}>{item.tipoNome}</p>
          <p style={styles.cardText}>{formattedPrice}</p>
        </div>
        <div style={styles.cardActions}>
          <button
            style={styles.removeButton}
            onClick={() => handleRemovePayment(item)}
          >
            <FontAwesomeIcon icon={faTrash} style={styles.trashIcon} size="lg" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {pagamentos.map((item, index) => (
        <div key={index}>{renderItem(item)}</div>
      ))}
    </div>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  cardContent: {
    flexDirection: 'column',
    alignItems: 'baseline',
    width: '50%',
  },
  cardActions: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%',
  },
  cardText: {
    margin: 0,
    fontSize: '16px',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  trashIcon: {
    color: '#ff0000',
  },
};

export default PaymentList;
