import React, { useState, useEffect } from "react";
import "./ModalItemSelecionado.css"; // Importe um arquivo CSS para os estilos

const ModalItemSelecionado = ({
  isVisible,
  onClose,
  onSave,
  produto,
  onDelete,
}) => {
  const [quantidade, setQuantidade] = useState(0);
  const [acrescimo, setAcrescimo] = useState(0);
  const [valorAcrescimo, setValorAcrescimo] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorPreco, setValorPreco] = useState(0);
  const [preco, setPreco] = useState(produto ? produto.preco.toString() : "0");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (produto) {
      setQuantidade(produto.quantidade.toString());
      setAcrescimo(produto.acrescimo.toString());
      setDesconto(produto.desconto.toString());
      setPreco(produto.preco);
      setValorPreco(produto.preco.toString().replace(",", "."));
      setValorAcrescimo(produto.valorAcrescimo.toString());
      setValorDesconto(produto.valorDesconto.toString());
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [produto, isVisible]);



  
  const handleSave = () => {
    console.log(preco);
    const valorSemSimbolo = preco.toString().replace(/[R$\s]/g, '');
    const valorComPonto = valorSemSimbolo.replace(',', '.');

    const updatedProduto = {
      ...produto,
      quantidade: parseFloat(quantidade),
      preco: parseFloat(valorComPonto),
      valorAcrescimo: valorAcrescimo,
      valorDesconto: valorDesconto,
      acrescimo: acrescimo,
      desconto: desconto,
    };
    onSave(updatedProduto);
    onClose();
  };


  const handleDelete = () => {
    if (window.confirm(`Deseja excluir o item ${produto.nome}?`)) {
      onDelete(produto);
      onClose();
    }
  };

  const formatarValor = (text) => {
    const numericText = text.replace(/[^0-9]/g, "");

    if (!numericText) {
      setPreco("");
      return;
    }

    const formattedValue = `${numericText.slice(0, -2)},${numericText.slice(-2)}`;
    setPreco(formattedValue);
    setValorPreco(formattedValue.replace(",", "."));
  };

  if (!isVisible) return null;

  return (
      <div className="div-principal">
       <h2>Editar Produto</h2>
        <div className="modal-header">
            <button className="close-button" onClick={onClose}>Voltar</button>
            <h2>{produto.nome}</h2>
          </div>

        <label className="titulo">Quantidade</label>
        <div className="input-group">
          <button
            className="botoes-quantidade"
            onClick={() =>
              setQuantidade((prev) =>
                Math.max(1, parseInt(prev) - 1).toString()
              )
            }
          >
            -
          </button>
          <input
            type="number"
            className="input-estilizado"
            value={quantidade}
            onChange={(e) => setQuantidade(e.target.value)}
          />
          <button
              className="botoes-quantidade"
            onClick={() =>
              setQuantidade((prev) => (parseInt(prev) + 1).toString())
            }
          >
            +
          </button>
        </div>

        <label className="titulo">Preço</label>
        <input
          className="input-estilizado"
          type="text"
          value={preco}
          
          onChange={(e) => formatarValor(e.target.value)}
        />



        <label className="titulo">Desconto</label>
        <div className="input-group">
          <button
            className="botoes-quantidade"
            onClick={() =>{

              console.log((parseFloat(quantidade) *
              parseFloat(valorPreco || 0) -
            parseFloat(desconto || 0) +
            parseFloat(valorAcrescimo || 0)));


            
            console.log( parseFloat(quantidade) *
            parseFloat(valorPreco || 0));


            console.log(desconto);



              if(
              (parseFloat(quantidade) *
              parseFloat(valorPreco || 0) -
            parseFloat(desconto || 0) +
            parseFloat(valorAcrescimo || 0)) > 0 && desconto >=0
              ){
                setValorDesconto(desconto);
              }

            }           
          }
          >
            ↓
          </button>
        <input
          className="input-estilizado"
          type="number"
          value={desconto}
          onChange={(e) => setDesconto(e.target.value)}
        />          
          <button
            className="botoes-quantidade"
            onClick={() =>
              {
                if(desconto >= 0 && desconto < 100){
                setValorDesconto(
                  (parseFloat(desconto) / 100) *
                    (parseFloat(preco) * parseFloat(quantidade)).toFixed(2),
                );
              }
             }
            }
          >
            %
          </button>      
      </div>



        <label className="titulo">Acréscimo</label>
        <div className="input-group">
          <button
            className="botoes-quantidade"
            onClick={() =>{
              setValorAcrescimo(acrescimo);
            }           
          }
          >
            ↑
          </button>
        <input
          className="input-estilizado"
          type="number"
          value={acrescimo}
          onChange={(e) => setAcrescimo(e.target.value)}
        />

         <button
            className="botoes-quantidade"
            onClick={() =>
              {
                if(acrescimo >= 0 && acrescimo < 100){
                setValorAcrescimo(
                  (parseFloat(acrescimo) / 100) *
                    (
                      parseFloat(preco) * parseFloat(quantidade) -
                      parseFloat(valorDesconto)
                    ).toFixed(2),
                );
              }
             }
            }
          >
            %
          </button>      
      </div>

        <label className="titulo">Total</label>
        <input
          className="input-estilizado"
          type="text"
          value={`R$ ${(
            parseFloat(quantidade) *
              parseFloat(valorPreco || 0) -
            parseFloat(valorDesconto || 0) +
            parseFloat(valorAcrescimo || 0)
          ).toFixed(2)}`}
          readOnly
        />

                <div className="button-container">

                <button onClick={handleDelete} className="excluir-button">
                    Excluir Item <span className="trash-icon">🗑️</span>
                </button>


                <div className="action-buttons">
                    <button onClick={onClose} className="cancel-button">
                    Cancelar
                    </button>
                    <button onClick={handleSave} className="confirm-button">
                    Confirmar
                    </button>
                </div>
                </div>

      </div>

  );
};

export default ModalItemSelecionado;
