import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFile, faFileInvoiceDollar, faPrint, faShoppingCart, faUndoAlt, faArrowLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Reimpressao from "./Reimpressao";
import { Button, Modal } from "antd";
import Cancelamento from "./Cancelamento";
import TelaVendas from "./TelaVendas";
import Orcamentos from "./Orcamentos/Orcamentos";

const Devolucao = () => <h1>Devolução</h1>;
const Log = () => <h1>Log</h1>;

const Vendas = () => {
  const [key, setKey] = React.useState(0);

  const resetStates = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [msgSairTela, setMsgSairTela] = useState('');

  const handleOpenModal = (component) => {
    setModalContent(component);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };
  const handleExitClick = () => {
    const confirmExit = window.confirm(`${msgSairTela}`);
    if (confirmExit) {
      resetStates();
      handleCloseModal();
    }
  };


  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const trataProdutos = produto => {
    setProdutos([...produtos, produto]);
  };

  return (
    <Container key={key}>

      <Button
        style={{ ...styles.buttonStyled, backgroundColor: '#841584' }}
        onClick={() => {handleOpenModal(<TelaVendas />)
          setMsgSairTela('Deseja abandonar a Venda?');
        }}
      >
        <FontAwesomeIcon icon={faShoppingCart} size="2x" />
        <ButtonText>Vendas</ButtonText>
      </Button>

      <Button onClick={() => {
        handleOpenModal(<Orcamentos />)
        setMsgSairTela('Deseja abandonar o Orçamento?');
      }
      } style={{ ...styles.buttonStyled, backgroundColor: '#FFA500' }} >
        <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
        <ButtonText>Orçamentos</ButtonText>
      </Button>

      <Button onClick={() => {
        handleOpenModal(<Cancelamento />)
        setMsgSairTela('Deseja sair da tela de Cancelamento?');
      }} style={{ ...styles.buttonStyled, backgroundColor: '#FF6347' }} >
        <FontAwesomeIcon icon={faBan} size="2x" />
        <ButtonText>Cancelamento</ButtonText>
      </Button>

      <Button onClick={() => {
        handleOpenModal(<Devolucao />)
        setMsgSairTela('Deseja sair da tela de Devolução?');
      }
      } style={{ ...styles.buttonStyled, backgroundColor: '#4682B4', opacity: '0.5' }} disabled={true}>
        <FontAwesomeIcon icon={faUndoAlt} size="2x" />
        <ButtonText>Devolução</ButtonText>
      </Button>

      <Button onClick={() => {
        handleOpenModal(<Reimpressao />)
        setMsgSairTela('Deseja sair da tela de Reimpressão?');
      }} style={{ ...styles.buttonStyled, backgroundColor: '#32CD32' }}>
        <FontAwesomeIcon icon={faPrint} size="2x" />
        <ButtonText>Reimpressão</ButtonText>
      </Button>

      <Button onClick={() => {
        handleOpenModal(<Log />)
        setMsgSairTela('Deseja sair da tela de Logs?');
      }} style={{ ...styles.buttonStyled, backgroundColor: '#000', opacity: '0.5' }} disabled={true}>
        <FontAwesomeIcon icon={faFile} size="2x" />
        <ButtonText>Log</ButtonText>
      </Button>

      <Modal
        visible={isModalVisible}
        onCancel={handleExitClick}
        footer={null}
        width="100%"
        // bodyStyle={{ padding: 0, margin: 0,height: "100vh" }}
        style={{ top: 0 }}
        centered
        closeIcon={null} // Remove o ícone de fechamento padrão
        className="full-screen-modal"

      >
        <ModalHeader>
          <Button onClick={handleExitClick} type="link" icon={<FontAwesomeIcon icon={faSignOutAlt} />} />
        </ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>

    </Container>
  );
};



const styles = {
  buttonStyled: {
    width: '150px',
    height: '150px',
    border: 'none',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '16px',
    margin: '10px',
  },
};



const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  position: relative;
  width:100%
  
`;


const ButtonText = styled.span`
  margin-top: 10px;
  font-weight: bold;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;

  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  background: #fff;
`;

const ModalBody = styled.div`
  padding: 0;
  margin:0;
  height:92vh;
  width:100%
`;

export default Vendas;
