import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';



const Parametros = ({
  isVisible,
  onClose,
  precoTotal,
  setPagamentoConcluido,
  setPagItem,
  pagamentos,
  setPagamentos,
  indPag,
  setIndPag,
}) => {
  const [accessToken, setAccessToken] = useState('');
  const [idUser, setIdUser] = useState('');
  const [restauranteId, setRestauranteId] = useState('');
  const [formasDePagamento, setFormasDePagamento] = useState([]);
  const [totalPago, setTotalPago] = useState(0);
  const [formaDePagamentoSelecionada, setFormaDePagamentoSelecionada] = useState('01');
  const [valor, setValor] = useState(`R$ ${(parseFloat(precoTotal) - parseFloat(totalPago)).toFixed(2)}`);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const userId = localStorage.getItem('idUsuario');
    setAccessToken(token || '');
    setIdUser(userId || '');
  }, []);

  useEffect(() => {
    if (accessToken && idUser) {
      const fetchRestaurante = async () => {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };
        try {
          const response = await axios.get(
            `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
            options
          );
          setRestauranteId(response.data.id);
        } catch (error) {
          console.error(error);
        }
      };
      fetchRestaurante();
    }
  }, [accessToken, idUser]);

  useEffect(() => {
    if (accessToken && restauranteId) {
      const fetchFormasPagamento = async () => {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };
        try {
          const response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/formas-pagamento`,
            options
          );
          const formas = response.data._embedded.formasPagamento.map(forma => ({
            label: forma.descricao,
            value: forma.codigo,
          }));
          setFormasDePagamento(formas);
        } catch (error) {
          console.error(error);
        }
      };
      fetchFormasPagamento();
    }
  }, [accessToken, restauranteId]);

  useEffect(() => {
    setValor(`R$ ${(parseFloat(precoTotal) - parseFloat(totalPago)).toFixed(2)}`);
  }, [precoTotal, totalPago]);

  const tratarValor = (text) => {
    let valorFormatado = text.replace(/\D/g, '');

    if (valorFormatado === '' || parseInt(valorFormatado) === 0) {
      setValor('R$ 0');
    } else {
      valorFormatado = parseFloat(valorFormatado) / 100;
      setValor(
        valorFormatado.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      );
    }
  };

  const salvarParametros = () => {
    onClose();
  };

  return (
    isVisible && (
        <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          <h3>Parâmetros da Nota</h3>
          <select
            style={{'padding': '10px 20px'}}
            value={indPag}
            onChange={(e) => setIndPag(e.target.value)}
          >
            <option value="0">Pagamento à vista</option>
            <option value="1">Pagamento a prazo</option>
            <option value="2">Outros</option>
          </select>
          <button onClick={salvarParametros} style={{backgroundColor:'black', color:'white','padding': '10px 20px'}}>Salvar Parâmetros</button>

        </div>
    )
  );
};

export default Parametros;
