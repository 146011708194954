import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";

const AcrescimoDescontoTotal = ( {
        onClose,
        isVisible,
        precoTotal,
        setPagamentoConcluido,
        setPagItem,
        pagamentos,
        setPagamentos,
        acresDescFrete,
        setAcresDescFrete,
        produtos,
        setProdutos,
        CalcularTotais,
        subTotal,
        setAcrescimoDescontosFreteConcluido}
  ) => {
  const [frete, setFrete] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [acrescimo, setAcrescimo] = useState(0);




  useEffect(() => {
    if(acresDescFrete){
      setFrete(acresDescFrete.frete); 
      setDesconto(acresDescFrete.descTotal);
      setAcrescimo(acresDescFrete.acresTotal);
    }
  }, []);


  const [quantidade, setQuantidade] = useState(0);

  const [valorAcrescimo, setValorAcrescimo] = useState(0);

  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorPreco, setValorPreco] = useState(0);

  const [isloading, setIsLoading] = useState(true);
  const [liberarAcrescimo, setLiberarAcrescimo] = useState(false);
  const [liberarDesconto, setLiberarDesconto] = useState(false);

  const handleSave = async () => {
    const produtosAux = await calcularDescontos();
    await calcularAcrescimosEFrete(produtosAux);
    const acresDescFreteAux = {
      acresTotal: acrescimo,
      descTotal: desconto,
      frete: frete,
    };
    setAcresDescFrete(acresDescFreteAux);

    CalcularTotais();
    setAcrescimoDescontosFreteConcluido(true);
    onClose();
  };

  const calcularDescontos = async () => {
    const produtosAux = produtos;

    var valorDeProdutosComDescontoZero = 0;
    var porcentagemEquivalenteDesconto = (valorDesconto / subTotal) * 100;

    if(produtosAux){   
       await produtosAux.map(produto => {
      if (produto.valorDesconto == 0) {
        valorDeProdutosComDescontoZero += produto.quantidade * produto.preco;
      }
    });
  
  
  
    await produtosAux.map((produto, index) => {
      if (produto.valorDesconto == 0) {
        produto.valorDesconto =
          produto.quantidade *
          produto.preco *
          (porcentagemEquivalenteDesconto / 100);
      }
    });
    setProdutos(produtosAux);
  }








 
    return produtosAux;
  };
  const calcularAcrescimosEFrete = async produtosAux => {
    if (valorAcrescimo > 0) {
      var valorDeProdutosComAcrescimoZero = 0;

      const subtotalAux = subTotal - valorDesconto;
      var porcentagemEquivalenteAcrescimo =
        (valorAcrescimo / subtotalAux) * 100;

      var totalDescontos = 0;

      await produtosAux.map(produto => {
        totalDescontos += produto.valorDesconto;
        if (produto.valorAcrescimo == 0) {
          valorDeProdutosComAcrescimoZero +=
            produto.quantidade * produto.preco - produto.valorDesconto;
        }
      });
      console.log('Conta');
      console.log('Conta');
      console.log('Conta');

      console.log(valorAcrescimo);
      console.log(subTotal);
      console.log(totalDescontos);
      var porcentagemEquivalenteAcrescimo =
        (valorAcrescimo / (subTotal - totalDescontos)) * 100;

      await produtosAux.map((produto, index) => {
        if (produto.valorAcrescimo == 0) {
          produto.valorAcrescimo =
            (produto.quantidade * produto.preco - produto.valorDesconto) *
            (porcentagemEquivalenteAcrescimo / 100);
          console.log('produto.valorAcrescimo');
          console.log(
            produto.quantidade * produto.preco - produto.valorDesconto,
          );
          console.log(porcentagemEquivalenteAcrescimo);
          console.log(porcentagemEquivalenteAcrescimo / 100);

          console.log(produto.valorAcrescimo);
        }
        // Calculando o valor de frete por produto
        let fretePorProduto = Math.floor(frete / produtosAux.length);
        let totalFreteDistribuido = fretePorProduto * produtosAux.length;
        let excedenteFrete = frete - totalFreteDistribuido;

        produto.frete = frete / produtosAux.length;

        if (index === produtosAux.length - 1) {
          produto.frete = fretePorProduto + excedenteFrete;
        } else {
          produto.frete = fretePorProduto;
        }
      });
    } else {
      // Calculando o valor de frete por produto
      let fretePorProduto = Math.floor(frete / produtosAux.length);
      let totalFreteDistribuido = fretePorProduto * produtosAux.length;
      let excedenteFrete = frete - totalFreteDistribuido;

      await produtosAux.map((produto, index) => {
        if (index === produtosAux.length - 1) {
          produto.frete = fretePorProduto + excedenteFrete;
        } else {
          produto.frete = fretePorProduto;
        }
      });
    }
    setProdutos(produtosAux);
  };

  const styles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      padding: 20,
      boxSizing: "border-box",
    },
    header: {
      fontSize: 24,
      marginBottom: 20,
    },
    section: {
      width: "100%",
      maxWidth: 400,
      marginBottom: 20,
      textAlign: "center",
    },
    label: {
      fontSize: 16,
      marginBottom: 8,
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
    },
    iconButton: {
      backgroundColor: "black",
      color: "white",
      fontSize: 16,
      width: 40,
      height: 40,
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    input: {
      width: 60,
      textAlign: "center",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: 400,
      marginTop: 20,
    },
    cancelButton: {
      backgroundColor: "red",
      color: "white",
      fontWeight: "bold",
      border: "none",
      width: "48%",
    },
    confirmButton: {
      backgroundColor: "green",
      color: "white",
      fontWeight: "bold",
      border: "none",
      width: "48%",
    },
    total: {
      marginTop: 20,
      backgroundColor: "#e0e0e0",
      padding: 10,
      borderRadius: 8,
      textAlign: "left",
      maxWidth: 400,
      width: "100%",
    },
    totalText: {
      margin: "4px 0",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Acréscimos e Descontos</h2>

      <div style={styles.section}>
        <p style={styles.label}>Desconto</p>
        <div style={styles.inputGroup}>
          <Button style={styles.iconButton} onClick={() => {
          if(subTotal > desconto){
                setValorDesconto(desconto);
          }
              }}>↓</Button>



















          <Input
            style={styles.input}
            value={desconto}
            onChange={(e) =>{
              console.log(e.target.value);
              if(e.target.value >= 0){
                console.log(e.target.value);
           
                  setDesconto(e.target.value);
                
              }
              if(e.target.value == ''){
                setDesconto(0);
              }

            }}
          />
          <Button style={styles.iconButton} onClick={() => {
            if(desconto >= 0 && desconto < 100){
              setValorDesconto(
                (parseFloat(desconto) / 100) * parseFloat(subTotal),
              );
            }
            
              }}>%</Button>
        </div>
      </div>

      <div style={styles.section}>
        <p style={styles.label}>Acréscimo</p>
        <div style={styles.inputGroup}>
          <Button style={styles.iconButton} onClick={() => {

                setValorAcrescimo(acrescimo);
              }}>↑</Button>
          <Input
            style={styles.input}
            value={acrescimo}
            onChange={(e) => {
              if(e.target.value >= 0){
                setAcrescimo(e.target.value);
              }
                }
            }
          />
          <Button style={styles.iconButton} onClick={() => {
              if(acrescimo >= 0 && acrescimo < 100){
                setValorAcrescimo(
                  (parseFloat(acrescimo) / 100) *
                    (parseFloat(subTotal) - parseFloat(valorDesconto)),
                );

              }

              }}>%</Button>
        </div>
      </div>

      <div style={styles.section}>
        <p style={styles.label}>Frete</p>
        <Input
          style={styles.input}
          value={frete}
          onChange={(e) => setFrete(e.target.value)}
        />
      </div>

      <div style={styles.buttonGroup}>
        <Button style={styles.cancelButton} onClick={onClose}>Cancelar</Button>
        <Button style={styles.confirmButton} onClick={handleSave}>Confirmar</Button>
      </div>

      <div style={styles.total}>
        <p style={styles.totalText}>{`Desconto: ${parseFloat(valorDesconto).toLocaleString(
              'pt-BR',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              },
            )}`}</p>
            
            <p style={styles.totalText}>{`Acréscimo: ${parseFloat(valorAcrescimo).toLocaleString(
              'pt-BR',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              },
            )}`}</p>
            <p style={styles.totalText}>
            {`Frete: ${parseFloat(frete).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}`}
          </p>
      </div>
    </div>
  );
};

export default AcrescimoDescontoTotal;
