import React, { useEffect, useState } from 'react';
import Pesquisar from './Pesquisar';
import { Card, Spin } from 'antd';
import './TelaVendas.css'; // Assumindo que os estilos foram movidos para um arquivo CSS
import ModalItemSelecionado from './ModalItemSelecionado';
import FinalizarVenda from './FinalizarVenda';
import Leitor from './Leitor';
import TesteQr from './LeitorQRCode';
import LeitorQRCode from './LeitorQRCode';
import axios from 'axios';
import BuscarOrcamentos from './Orcamentos/BuscarOrcamentos';

const TelaVendas = () => {
  const [isPesquisar, setIsPesquisar] = useState(false); // Estado para controlar a exibição do conteúdo de pesquisa
  const [isFinalizarVenda, setIsFinalizarVenda] = useState(false); // Estado para controlar a exibição do conteúdo de finalizar venda
 const [isBuscarOrcamento, setIsBuscarOrcamento] = useState(false); 
  const [acresDescFreteConcluido, setAcresDescFreteConcluido] = useState(false);
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [quantidadeDeItens, setQuantidadeDeItens] = useState(0);
  const [precoTotal, setPrecoTotal] = useState(0);
  const [totalSemFrete, setTotalSemFrete] = useState(0);
  const [totalDescontos, setTotalDescontos] = useState(0);
  const [totalAcrescimos, setTotalAcrescimos] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const operador = window.localStorage.getItem("operador");
  const [isLoading, setIsLoading] = useState(false);
  const [nomeCupom, setNomeCupom] = useState('');
  const [documentoCupom, setDocumentoCupom] = useState('');
  const [numeroEvento, setnumeroEvento] = useState('');
  const [isModalVisibleBuscarOrcamento, setIsModalVisibleBuscarOrcamento] =
    useState(false);
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [isModalVisibleItem, setModalVisibleItem] = useState(false);
  const [isLerCodigo, setIsLerCodigo] = useState(false);
  const [orcamentoId, setOrcamentoId] = useState('');


  const [cardAberto, setCardAberto] = useState('');
  const [consumidorIdentificado, setConsumidorIdentificado] = useState(false);
  const [consumidor, setConsumidor] = useState(null);
  const [acresDescFrete, setAcresDescFrete] = useState({
    acresTotal: '0',
    descTotal: '0',
    frete: '0',
  });
  const [pagItem, setPagItem] = useState([]);






  const [recalcular, setRecalcular] = useState(false);


  const handlePesquisarClick = () => {
    setIsPesquisar(true); // Mostrar a tela de pesquisa
  };

  const handleVoltarClick = () => {
    setIsPesquisar(false); // Voltar para a tela de vendas
  };

  const trataProdutos = produto => {
    setProdutos([...produtos, produto]);
    
  };


  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const CalcularTotais = () => {
    var quantidadeDeItensAux = 0;
    var precoTotalAux = 0;
    var totalSemFreteAux = 0;
    var subTotalAux = 0;
    var totalDescontosAux = 0;
    var totalAcrescimosAux = 0;

    produtos.map(produto => {
      console.log(produto);
      quantidadeDeItensAux = quantidadeDeItensAux + produto.quantidade;

      subTotalAux = subTotalAux + produto.preco * produto.quantidade;
      totalSemFreteAux =
        totalSemFreteAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo);

      precoTotalAux =
        precoTotalAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo) +
        parseFloat(produto.frete ? produto.frete : 0);

      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log(precoTotalAux);

      totalDescontosAux += parseFloat(produto.valorDesconto);
      totalAcrescimosAux += parseFloat(produto.valorAcrescimo);
    });
    setQuantidadeDeItens(quantidadeDeItensAux);
    setPrecoTotal(precoTotalAux);
    setTotalSemFrete(totalSemFreteAux);
    setSubtotal(subTotalAux);
    setTotalDescontos(totalDescontosAux);
    setTotalAcrescimos(totalAcrescimosAux);
  };

  const openModalItem = produto => {
    setSelectedProduto(produto);
    setModalVisibleItem(!isModalVisibleItem);
  };
  useEffect(() => {
    CalcularTotais();
  }, [produtos, recalcular]);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9' }}>
      <h1 style={{ textAlign: 'center',marginBottom:'5px', marginTop:0 }}>Vendas</h1>

      {/* Se isPesquisar for verdadeiro, exibe o conteúdo de pesquisa */}
      {isBuscarOrcamento ? (
        <BuscarOrcamentos
        closeModal={()=>{
          setIsBuscarOrcamento(false);
        }}
        tratarProdutosOrcamento={orcamento => {
          setIsLoading(true);
          function validarDocumento(documento) {
            // Remover caracteres não numéricos
            documento = documento.replace(/[^\d]+/g, '');
        
            // Verificar se o documento é um CPF
            if (documento.length === 11) {
              let cpf = documento;
              let add = 0;
              for (let i = 0; i < 9; i++) {
                add += parseInt(cpf.charAt(i)) * (10 - i);
              }
              let rev = 11 - (add % 11);
              if (rev === 10 || rev === 11) rev = 0;
              if (rev !== parseInt(cpf.charAt(9))) return false;
        
              add = 0;
              for (let i = 0; i < 10; i++) {
                add += parseInt(cpf.charAt(i)) * (11 - i);
              }
              rev = 11 - (add % 11);
              if (rev === 10 || rev === 11) rev = 0;
              if (rev !== parseInt(cpf.charAt(10))) return false;
        
              return true;
            }
        
            // Verificar se o documento é um CNPJ
            else if (documento.length === 14) {
              let cnpj = documento;
              let tamanho = cnpj.length - 2;
              let numeros = cnpj.substring(0, tamanho);
              let digitos = cnpj.substring(tamanho);
              let soma = 0;
              let pos = tamanho - 7;
              for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) pos = 9;
              }
              let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
              if (resultado !== parseInt(digitos.charAt(0))) return false;
        
              tamanho = tamanho + 1;
              numeros = cnpj.substring(0, tamanho);
              soma = 0;
              pos = tamanho - 7;
              for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) pos = 9;
              }
              resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
              if (resultado !== parseInt(digitos.charAt(1))) return false;
        
              return true;
            }
        
            // Se não for nem CPF nem CNPJ, retorna false
            else {
              return false;
            }
          }
          if(validarDocumento(orcamento.cpf)){
            if (accessToken) {
              const options = {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + accessToken,
                },
              };
    
              axios
                .get(
                  `https://techspdv-api-d3e739785e34.herokuapp.com/v1/clientes/loja/${orcamento.idEmpresa}/cpfcnpj/${orcamento.cpf}`,
                  options,
                )
                .then(consumidorResp => {
                  console.log(
                    `https://techspdv-api-d3e739785e34.herokuapp.com/v1/clientes/loja/${orcamento.idEmpresa}/cpfcnpj/${orcamento.cpf}`,
                  );
    
                  if (consumidorResp) {
                    console.log(consumidorResp.data);
                    if (consumidorResp.data) {
                      setConsumidor(consumidorResp.data);     
                      setConsumidorIdentificado(true);         
                    }                   
                  }
                })
              }
          }
   
          const pagamentosAux = [];
          orcamento.pagamentos.map( pagamento => {

            const novoPagamento = {
              tipo: pagamento.codigoFiscal,
              tipoNome: pagamento.descricao,
              valor: pagamento.valor,
            }
            pagamentosAux.push(novoPagamento);

          })

          setPagamentos(pagamentosAux);


      
          let pagItemAux = new Array();
          pagamentosAux.map(pagamento => {      
            const pagamentoFormatado = {
              tPag: pagamento.tipo,
              vPag: pagamento.valor.toFixed(2),
              xPag: pagamento.tipoNome,
            };
            pagItemAux.push(pagamentoFormatado);
          });
          setPagItem(pagItemAux);

          setPagamentoConcluido(true);

          orcamento.itens.map(async prod => {
            console.log(prod.idReferencialProduto);
            try {
              const options = {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + accessToken,
                },
              };
              console.log(
                `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${
                  orcamento.idEmpresa
                }/produtos/${prod.idReferencialProduto}?page=${0}`,
              );
              const response = await axios.get(
                `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${
                  orcamento.idEmpresa
                }/produtos/${prod.idReferencialProduto}?page=${0}`,
                options,
              );
       
              const produtosAux = produtos;
              if (response.data) {

                response.data.quantidade = prod.quantidade;
                response.data.acrescimo = prod.valorTotalAcrescimo;
                response.data.desconto = prod.valorTotalDesconto;
                response.data.valorAcrescimo = prod.valorTotalAcrescimo;
                response.data.valorDesconto = prod.valorTotalDesconto;
                response.data.frete = prod.vfrete?prod.vfrete:0;

                produtosAux.push(response.data);


              
                CalcularTotais();
              }

              setProdutos(produtosAux);
            } catch (error) {
              setIsLoading(false);
              console.error('Erro ao fazer a requisição:', error);
            }
          });
          setOrcamentoId(orcamento.id);
        
          let acresDescFreteAux = acresDescFrete;
          acresDescFreteAux.frete = orcamento.valorFrete;
          setAcresDescFrete(acresDescFreteAux);
          console.log('orcamento');
          console.log('orcamento');
          console.log(orcamento);
          setIsLoading(false);

        }}
      />

      ) :isPesquisar ? (
          <Pesquisar  
          onItemPress={trataProdutos}
          setPagamentoConcluido={setPagamentoConcluido}
          setPagamentos={setPagamentos} 
          closeModal={handleVoltarClick}
          ></Pesquisar>
      ) : isLerCodigo ? (
          /*  <Leitor
              closeModal={() => {
              setIsLerCodigo(false);
            }}
            onItemPress={trataProdutos}
            setPagamentoConcluido={setPagamentoConcluido}
            setPagamentos={setPagamentos}>
            </Leitor>*/
            <LeitorQRCode/>
      ) :
        isFinalizarVenda? (<FinalizarVenda
          closeModal={()=>{setIsFinalizarVenda(false)}}
          onItemPress={trataProdutos}
          setConsumidorIdentificado={setConsumidorIdentificado}
          consumidorIdentificado={consumidorIdentificado}
          nomeCupom={nomeCupom}
          documentoCupom={documentoCupom}
          setNomeCupom={setNomeCupom}
          setDocumentoCupom={setDocumentoCupom}
          consumidor={consumidor}
          setConsumidor={setConsumidor}
          precoTotal={precoTotal}
          pagamentoConcluido={pagamentoConcluido}
          setPagamentoConcluido={setPagamentoConcluido}
          pagamentos={pagamentos}
          setPagamentos={setPagamentos}
          produtos={produtos}
          setProdutos={setProdutos}
          subTotal={subTotal}
          CalcularTotais={CalcularTotais}
          acresDescFrete={acresDescFrete}
          setAcresDescFrete={setAcresDescFrete}
          totalDescontos={totalDescontos}
          totalAcrescimos={totalAcrescimos}
          acresDescFreteConcluido={acresDescFreteConcluido}
          setAcresDescFreteConcluido={setAcresDescFreteConcluido}
          setPagItem={setPagItem}
          pagItem={pagItem}
        
        ></FinalizarVenda>) :  (
        isModalVisibleItem ? (
          <ModalItemSelecionado
          isVisible={isModalVisibleItem}
          onClose={() => setModalVisibleItem(false)}
          onSave={updatedProduto => {
            console.log(updatedProduto);
            produtos[updatedProduto.index] = updatedProduto;
            //produto = updatedProduto;
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          onDelete={item => {
            produtos.splice(item.index, 1);
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          produto={selectedProduto}
        />
      ) : (
        // Caso contrário, exibe a tela de vendas
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
           {/* <button
              style={{
                flex: 1,
                marginRight: '10px',
                backgroundColor: '#800080',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
              onClick={()=>{setIsLerCodigo(true)}} 
            >
              <span style={{ display: 'block', fontSize: '24px' }}>📷</span>
              Ler Código
            </button>*/}
            <button
              onClick={handlePesquisarClick}
              style={{
                flex: 1,
                marginRight: '10px',
                backgroundColor: '#000',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>🔍</span>
              Pesquisar
            </button>
            <button
              style={{
                flex: 1,
                backgroundColor: '#90ee90',
                color: '#000',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
              onClick={()=>{setIsFinalizarVenda(true)}} 
            >
              <span style={{ display: 'block', fontSize: '24px' }}>✔️</span>
              Finalizar Venda
            </button>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button
              style={{
                backgroundColor: '#000',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
                width: '100%',
              }}
              onClick={()=>{
                setIsBuscarOrcamento(true);
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>📄</span>
              Buscar Orçamento
            </button>
          </div>
          <div style={{ backgroundColor: '#d3d3d3', padding: '15px', borderRadius: '5px' }}>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{getDataAtual()}</p>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{`Operador: ${operador}`}</p>
            <p style={{ margin: '5px 0' ,fontWeight:'bold', fontSize:'14px'}}>Total da Venda: {totalSemFrete.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}</p>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}> {`Total de Itens na Venda: ${quantidadeDeItens}`}</p>
          </div>


          {produtos.map((produto, index) => {
          produto.index = index;
          console.log(produto);

          return (
            <>
              {isLoading ? (
                <Spin />
              ) : (



                <div className='cardT' onClick={()=>{openModalItem(produto)}}>
                  <span className="cardTextNome">{produto.nome}</span><br/>
                  <span className="cardText">{`${produto.ean}`}</span><br/>
                  <span className="cardText">
                    {`${produto.quantidade} X ${produto.preco.toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(produto.quantidade * produto.preco).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} - ${parseFloat(produto.valorDesconto).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} + ${parseFloat(produto.valorAcrescimo).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(
                      parseFloat(produto.quantidade) *
                        parseFloat(produto.preco) -
                      parseFloat(produto.valorDesconto) +
                      parseFloat(produto.valorAcrescimo)
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </span>
                </div>
              
             
                      
             )}


            </>
          );
        })}
        </div>
        )
      )}
    </div>
  );
};

export default TelaVendas;
